import React, {useState, useCallback} from 'react';
import {Stack, Subheading, ChoiceList} from '@shopify/polaris';
import './offerTypeCheckbox.css';

const OfferTypeCheckbox = ({offerType, changeOfferType}) => {
  const [selected, setSelected] = useState(offerType);

  const handleChange = useCallback((value) => {
    setSelected(value[0]);
    changeOfferType(null, value[0]);
  }, []);

  return (
    <div className='Offer-type_wrapper'>
      <Stack>
        <Subheading>Product selection</Subheading>
        <ChoiceList
          title="Product selection<"
          titleHidden
          choices={[
            {
              label: 'Manual',
              value: 'manual',
              helpText: 'Manually choose the products to show as an upsell',
            },
            {
              label: 'ShopBrain',
              value: 'ai',
              helpText: 'Let our algorithm show the best product recommendations',
            },
            {
              label: 'Autopilot',
              value: 'autopilot',
              helpText: 'Show random items from a collection or with a certain tag',
            },
          ]}
          selected={selected}
          onChange={handleChange}
        />
      </Stack>
    </div>
  );
};

export default OfferTypeCheckbox;